<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-img src="./../../assets/images/login.jpg">
      <v-layout wrap justify-center align-center :style="{
        height:
          $vuetify.breakpoint.name == 'xs'
            ? '100%'
            : $vuetify.breakpoint.name == 'sm'
              ? '100%'
              : $vuetify.breakpoint.name == 'md'
                ? '100vh'
                : $vuetify.breakpoint.name == 'lg'
                  ? '100vh'
                  : '100vh',
      }">
        <v-flex xs12 sm8 md5 lg4 align-self-center>
          <v-card rounded="xl" elevation="24">
            <v-layout wrap justify-center pt-10>
              <v-flex xs10 sm6 md4 lg4>
                <v-layout wrap justify-center>
                  <v-flex xs2 sm2 md2><v-img src="./../../assets/logoeco.png" contain height="25px"
                      width="25px"></v-img></v-flex>
                  <v-flex xs10>
                    <span style="font-family: pollerOneRegular; font-size: 14px">ECOLedger</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="status != true">
              <v-flex xs12 py-4>
                <span style="font-family: montserratBold; font-size: 20px">Trouble Logging In?
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="status != true">
              <v-flex xs10 sm10 text-left>
                <v-layout wrap>
                  <v-flex text-center>
                    <span style="font-family: montserratRegular; font-size: 14px">Enter your email and we will send you an
                      OTP to get back
                      into your Account</span>
                  </v-flex>
                  <v-flex pt-2>
                    <span style="font-family: montserratRegular; font-size: 13px">Email Address</span>
                    <v-text-field outlined dense v-model="email" placeholder="Email"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs10 sm10 py-3>
                <v-btn rounded block color="#7CB9E8" dark @click="sentOTP()">
                  <span style="font-size: 12px; font-family: montserratSemiBold">Send Otp</span>
                </v-btn>
              </v-flex>
              <v-flex xs10 py-3>
                <span style="font-family: montserratRegular; font-size: 14px">Or
                </span>
                <router-link to="/register">
                  <span style="
                        font-family: montserratBold;
                        font-size: 14px;
                        color: #000000;
                      ">Register</span>
                </router-link>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="status == true">
              <v-flex xs12 py-4>
                <span style="font-family: montserratBold; font-size: 20px">Reset Password
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="status == true">
              <v-flex xs10 sm10 md10 lg10 text-left>
                <v-layout wrap>
                  <v-flex text-left>
                    <span style="font-family: montserratRegular; font-size: 14px">Enter the OTP sent to you at {{ email }}
                    </span><br />
                    <span style="font-family: montserratRegular; font-size: 14px">OTP Verification</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-layout wrap justify-center pb-5>
                <v-flex xs10 sm10 pt-8 text-center>
                  <!-- <vie-otp-input
                    inputClasses="otp-input"
                    :numInputs="4"
                    separator
                    :shouldAutoFocus="true"
                    @on-complete="validateOTP"
                  /> -->
                  <v-text-field :hide-details="true" outlined dense v-model="otp" placeholder="Enter the otp"
                    class="centered-input"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <span v-if="!otpexpired" style="
                            font-family: poppinsmedium;
                            font-size: 12px;
                            color: #393c4a;
                          ">Resend otp in {{ timeLeft }}</span>
                      <span v-if="otpexpired" style="
                            font-family: poppinsmedium;
                            font-size: 12px;
                            color: #7cb9e8;
                            cursor: pointer;
                          " @click="sentOTP">Request again</span>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="msg2" py-4 wrap>
                    <v-flex text-center>
                      <span style="
                            font-family: poppinslight;
                            font-size: 14px;
                            color: red;
                          ">{{ msg2 }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs10>
                  <v-layout wrap justify-center py-5>
                    <v-flex xs12 text-left>
                      <span style="font-family: montserratRegular; font-size: 14px">New password must be have a Minimum of
                        14 characters
                        with any special character.</span>
                      <v-flex xs12 sm12 text-left py-2>
                        <span style="
                              font-family: montserratRegular;
                              font-size: 13px;
                            ">New password</span>
                        <v-text-field outlined dense placeholder="New password" ref="password" :rules="rules"
                          v-model="password"></v-text-field>
                        <span @click="autoGenerate()" style="
                              font-family: montserratSemiBold;
                              font-size: 12px;
                              color: green;
                              cursor: pointer;
                            ">Auto generate a secure password</span>
                        <br />
                        <span style="
                              font-family: montserratRegular;
                              font-size: 13px;
                            ">Confirm password</span>
                        <v-text-field ref="confirmPassword" placeholder="Confirm Password" outlined dense :rules="check"
                          v-model="confirmPassword"></v-text-field>
                      </v-flex>
                    </v-flex>
                    <v-flex xs12 sm12 py-3 text-center>
                      <v-btn rounded block color="#7CB9E8" dark @click="resetPassword()">
                        <span style="
                              font-size: 12px;
                              font-family: montserratSemiBold;
                            ">reset password</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>
<script>
// import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
export default {
  components: {
    // VieOtpInput,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
      phone: null,
      otp: null,
      timeLeft: "00:00",
      intervalTimer: null,
      otpexpired: false,
      email: "",
      status: "",
      password: "",
      confirmPassword: "",
      rules: [
        (v) => /^(?=.*[0-9])/.test(v) || "Password must contain one number",
        (v) =>
          /^.{10,15}$/.test(v) ||
          "Password must contain minimum 10 characters or maximum 15 characters",
        (v) =>
          /^(?=.*[!@#$%^&*])/.test(v) ||
          "Password must contain one special character",
        (v) =>
          /^(?=.*[a-z])/.test(v) ||
          "Password must contain one lowercase letter",
        (v) =>
          /^(?=.*[A-Z])/.test(v) ||
          "Password must contain one uppercase letter",
      ],
      check: [(v) => v === this.password || "Passwords do not match"],
    };
  },
  beforeMount() {
    this.setTime(600);
  },
  methods: {
    // validateOTP(value) {
    //   this.otp = value;
    // },
    sentOTP() {
      var userData = {};
      userData["email"] = this.email;
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/forget/password/send/otp",
        params: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.setTime(600);
            this.status = response.data.status;
            this.msg = "OTP Sent Successfully";
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    resetPassword() {
      var userData = {};
      userData["otp"] = this.otp;
      userData["email"] = this.email;
      userData["password"] = this.password;

      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/forget/reset/password",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.status = response.data.status;
            this.msg = "Reset Password Successfully";
            this.showSnackBar = true;
            this.$router.push("/loginForm");

          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    autoGenerate() {
      axios({
        method: "GET",
        url: "/password/random",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            if (response.data.status) {
              //
              this.password = response.data.password;
              this.confirmPassword = this.password;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setTime(seconds) {
      clearInterval(this.intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.countdown(end);
    },
    countdown(end) {
      this.intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);
        if (secondsLeft == 0) this.otpexpired = true;
        if (secondsLeft > 0) this.otpexpired = false;
        if (secondsLeft < 0) {
          clearInterval(this.intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;
      var seconds1 = seconds;
      if (seconds < 10) seconds1 = "0" + seconds;
      this.timeLeft = "0" + minutes + " : " + seconds1;
    },
  },
};
</script>
<style scoped>
.centered-input>>>input {
  text-align: center;
}
</style>
